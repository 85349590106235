import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { environment } from '../../../environments/environment';

@Injectable()
export class AppInsightsService {
    private angularPlugin = new AngularPlugin();
    private appInsights = new ApplicationInsights({
        config: {
            connectionString: environment.appInsights.connectionString,
            extensions: [this.angularPlugin],
            extensionConfig: {
                [this.angularPlugin.identifier]: {
                    router: this.router
                }
            }
        }
    });

    constructor(private router: Router) {
    }

    load(): void {
        this.appInsights.loadAppInsights();
        const telemetryInitializer = (envelope: ITelemetryItem) => {
            if (!envelope.tags) {
                envelope.tags = [];
            }
            envelope.tags['ai.cloud.role'] =
                environment.appInsights.cloudRoleName;
        };
        this.appInsights.addTelemetryInitializer(telemetryInitializer);
    }

    trackEvent(name: string): void {
        this.appInsights.trackEvent({ name });
    }

    trackTrace(message: string): void {
        this.appInsights.trackTrace({ message });
    }
}
